import { useMutation } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import BottomDrawer from "components/BottomDrawer";
import { ADMIN_SEARCH_MEMBERS } from "data/queries/community";
import { ADMIN_CREATE_USER_ACCOUNT } from "data/queries/users";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';

const UserAccountSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  email: Yup.string()
    .required('Email is required'),
});

function CustomToolbar() {
  const [open, setOpen] = useState(false);
  const [createUserAccount, { loading }] = useMutation(ADMIN_CREATE_USER_ACCOUNT, {
    refetchQueries: [ADMIN_SEARCH_MEMBERS]
  });

  const handleSubmit = async (values) => {
    if (loading) return;
    await createUserAccount({
      variables: {
        input: values
      }
    });
    // refetch();
    setOpen(false);
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2, mx: 2 }}>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
      <Button variant="contained" onClick={() => setOpen(true)}>ADD MEMBER</Button>
      <BottomDrawer 
        isOpen={open} 
        onClose={() => setOpen(false)}
        title="Add Member"
        size="medium"
      >
        <Formik
          enableReinitialize
          validationSchema={UserAccountSchema}
          initialValues={{}}
          onSubmit={async (values, { setSubmitting, isSubmitting, resetForm }) => {
            if (isSubmitting) return;
            await handleSubmit(values);
            resetForm();
            setSubmitting(false);
          }}
        >
          {({
            errors,
            touched,
            resetForm,
            getFieldProps,
          }) => (
            <Form>
              <Stack direction='column' gap={2} sx={{ mb: 2, mt: 3 }}>
                <TextField
                  label="First Name"
                  fullWidth
                  required
                  error={touched.firstName && !!errors.firstName}
                  helperText={errors.firstName}
                  {...getFieldProps('firstName')}
                />
                <TextField
                  label="Last Name"
                  fullWidth
                  required
                  error={touched.lastName && !!errors.lastName}
                  helperText={errors.lastName}
                  {...getFieldProps('lastName')}
                />
                <TextField
                  label="Email"
                  fullWidth
                  required
                  error={touched.email && !!errors.email}
                  helperText={errors.email}
                  {...getFieldProps('email')}
                />
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Button 
                  variant="contained" 
                  type="submit"
                  disabled={loading}
                >
                  ADD MEMBER
                </Button>
                <Button 
                  variant="contained" 
                  sx={{ backgroundColor: 'transparent' }}
                  onClick={() => {
                    setOpen(false);
                    resetForm();
                  }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </BottomDrawer>
    </Stack>
  );
}

export default CustomToolbar;
